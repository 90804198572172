import React from "react"
import styles from "../css/banner.module.css"
const Banner = ({ title, tag, info, children }) => {
  return (
    <div className={styles.banner}>
      <h1>{title}</h1>
        <h3>{tag}</h3>
      <p>{info}</p>
      {children}
    </div>
  )
}

export default Banner
